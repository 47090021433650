.tabGroup {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-left: 200px;
  }

  .login-container {
    max-width: 400px;
    margin: auto;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    margin-bottom: 1rem;
    text-align: center;
  }

  .contact-container {
    max-width: 600px;
    margin: auto;
    padding: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.2); /* Light border with transparency */
    border-radius: 8px;
    box-shadow: none; /* Remove the shadow */
    background-color: rgba(255, 255, 255, 0); /* Fully transparent background */
    color: #fff; /* Ensure text is visible against the background image */
    position: relative; /* Make sure it can be positioned over the background */
  }

  .contact-page {
    position: relative;
    background-image: url('../PAMed.png');
    background-size: cover;
    background-position: center;
    height: 600px;
    color: white;
    width: 100%; /* Ensure the hero section is full width */
  }
  
  .contact-form {
    margin-top: 1rem;
  }
  
  .form-field {
    margin-bottom: 1rem;
  }
  
  .submit-button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1.1rem;
  }
  
  .message {
    margin-bottom: 1.5rem;
  }

  /* Home.js */

  body {
    margin: 0;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  /* Home Container */
  .home-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* Hero Section */
  .hero-section {
    background: url('https://via.placeholder.com/1600x600') no-repeat center center;
    background-size: cover;
    color: white;
    padding: 5rem 0;
  }
  
  .hero-title {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: black;
  }
  
  .cta-button {
    margin-top: 1rem;
  }
  
  /* Property Highlights */
  .property-highlights {
    padding: 2rem 0;
    background-color: #f8f8f8;
  }
  
  .section-header {
    margin-bottom: 2rem;
  }
  
  /* About Section */
  .about-section {
    padding: 2rem 0;
  }
  
  /* Contact Section */
  .contact-section {
    padding: 2rem 0;
    background-color: #f8f8f8;
  }
  
  .contact-section p {
    margin-bottom: 1.5rem;
  }