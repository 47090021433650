html, body {
    padding: 0 !important;
    margin:0 !important;
    width: 100%;
}

.bold {
    font-weight: bold !important;
    font-size: 115% !important;
}

.button-hamburger {
    min-width: 50px;
    max-width: 50px;
}

.center-menu {
    justify-content: center !important;
}

.color-white {
    color: white !important;
}

.container-foot {
    padding: 0px;
}

.content-container {
    margin-top: 180px; /* This one is controlling the width of the hero content */
    margin-left: -1px;
    margin-right: -1px;
}

@media only screen and (max-width: 990px) {
    .content-container {
        margin-top: 160px; /* This one is controlling the width of the hero content */
        margin-left: -1px;
        margin-right: -1px;
    }
}

.content-outlet {
    margin: 0px !important;
    padding: 0px !important;
}

.content-segment {
    padding-left: 0px !important;
    padding-right: 0px !important;
    border: 0px !important;
    width: 101vw !important;
    height: auto !important;
}

@media only screen and (max-width: 990px) {
    .content-segment {
        padding-top: 0 !important;
        margin-top: -15px !important;
    }
}

.content-segment .sidebar {
    background: none !important;
    height: 375px !important;
}

.foot-text {
    font-size: 11px !important;
}

.footer-1 {
    font-weight: bold;
    font-size: 16px;
    color: black;
    text-align: left !important;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-wrap: nowrap;
}

@media only screen and (max-width: 990px) {
    .footer-1 {
        font-weight: bold;
        font-size: 15px;
        color: black;
        text-align: left !important;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        text-wrap: nowrap;
    }
}

.footer-2 {
    text-align: left;
    color: black;
    font-size: 15px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    height: 18px;
    text-wrap: nowrap;
}

.footer-bottom {
    text-align: center;
    padding: 15px;
    width: 100%; /* Ensure the footer is full width */
    color: #be8e14;
    background-color: #012f3f; /* Optionally make the footer background match the theme */
}
  
.footer-bottom p {
    font-size: 14px;
    margin-top: 0;
}

.footer-column {
    width: 23% !important;
    min-width: 285px !important;
}

.footer-column1 {
    width: 20% !important;
    min-width: 285px !important;
    margin-right: 20px !important;
}

.footer-column2 {
    width: 22% !important;
    min-width: 400px !important;
}

.grid-2 {
    justify-content: end;
    padding-left: 75px;
}

@media only screen and (max-width: 990px) {
    .grid-2 {
        justify-content: center !important;
        padding-left: 0px !important;
    }
  }

.grid-row {
    margin: 0 !important;
    padding: 0 !important;
}

.grow {
    background-color: #012f3f;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.grow-2 {
    padding:0 !important;
    margin:0 !important;
}

.layout-font-mobile {
    font-weight: normal !important;
}

.logo {
    height: 135px;
    width: 360px;
}

@media only screen and (max-width: 990px) {
    .logo {
        padding-left: 15px;
        height: 115px;
        width: 320px;
    }
  }

.logo-2 {
    height: 155px;
    width: 380px;
}

@media only screen and (max-width: 990px) {
    .logo-2 {
        padding: 0px !important;
        height: 115px;
        width: 320px;
        padding-left: 0px !important;
    }
  }

.logo-3 {
    height: 115px;
    width: 115px;
    padding: 18px;
}

.logo-4 {
    height: 115px;
    width: 200px;
    padding: 18px;
}

.logo-5 {
    height: 115px;
    width: 115px;
    padding: 18px;
    margin-left: 23px;
    margin-right: 24px;
}

.menu-color {
    color: white !important;
}

.menu-color .item {
    color: white !important;
}


.menu-color .item:hover {
    color: #bfa614!important;
    background-color: inherit !important;
}

.menu-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; /* Ensures it stays above other content */
    background-color: white;
    margin: 0 !important;
    padding: 0 !important;
    
}

.menu .item {
    margin: 0 !important;
}

.menu-restraint {
    white-space: normal;
    word-wrap: break-word;
    text-align: center;
    width: 155px;
    overflow: hidden;
}

.menu-restraint:hover {
    color: #bf8e13 !important;
}

.menu-restraintN {
    color: white !important;
}

.mlogo {
    height: 64px;
    width: 64px;
}

.num-color {
    color: #bfa614!important;
    font-size: 150% !important;
}

.pre-footer {
    background-color: grey;
    padding: 40px !important;
  }

@media only screen and (max-width: 990px) {
    .pre-footer {
        background-color: grey;
        padding: 20px !important;
        padding-left: 0 !important;
      }
}

.popup {
    background-color: #012f3f !important;
    border-top-color: #be8e14 !important;
    border-left: #012f3f !important;
    border-right: #012f3f !important;
    border-bottom: #012f3f !important;
    border-radius: 0 !important;
    border-top-width: 3px !important;
    color:#012f3f !important;
}

.popup:before, .popup:after {
    display: none;
}

.popup-button {
    background-color:#012f3f !important;
    color:white !important;
    text-wrap: nowrap !important;
}

.popup-button-1 {
    background-color:#012f3f !important;
    color:white !important;
    text-wrap: nowrap !important;
    border-bottom: 1px;
    border-top: 1px;
    border-color: gray;
}

.popup-button-1:hover {
    color: #bfa614 !important;
}

.popup-button:hover {
    color: ç!important;
}

.popup-menu {
    background-color: rgba(20, 45, 63, 0.85) !important;
}

.popup-menu-item {
    color: white !important;
    border-bottom-color: black !important;
    border-bottom: 4px !important;
    width: 150px !important;
}

.popup-menu-item:hover {
    background-color: #bfa614!important;
}

.right-aligned-items .menu {
    justify-content: right;
    display: flex;
}

.sidebar-dropdown {
    max-width: 35vw !important;
    text-align: left !important;
    height: auto; /* Adjust the height to fit the content */
    max-height: 100vh; /* Ensures the sidebar doesn't extend past the screen height */
    overflow-y: auto;
}

.sidebar-dropdown .menu {
    flex-grow: 1; /* Make the menu take up available space */
}

.sidebar-dropdown

.tab-list {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: none;
}

.top-row {
    padding-right: 25px !important;
    padding-top: 25px !important;
    max-width: 90%;
    background-color: white;
}

@media only screen and (max-width: 990px) {
    .top-row {
        padding-top: 25px !important;
        max-width: 100% !important;
        background-color: white;
    }
  }

.wrap-text {
    white-space: normal;
    word-wrap: break-word;
    width: 170px;
    text-align: right;
    overflow: hidden;
}

.wrap-text2 {
    white-space: normal;
    word-wrap: break-word;
    width: 170px;
    text-align: right;
    overflow: hidden;
}