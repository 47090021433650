.custom-list {
  list-style-image: url('../pages/pictures/check.png');
}

.custom-list1 {
  list-style-image: url('../pages/pictures/check.png');
  max-width: 250px;
  text-align: left;
  text-wrap: wrap;
}

.header-color {
  color: #be8e14 !important;
  text-align: left !important;
  margin-left: -18px !important;
}

.header-color1 {
  color: #be8e14 !important;
  text-align: left !important;
  min-width: 150px !important;
}

.no-whitespace {
  margin: 0px !important;
}

.price-grid {
  width: 100%;
  align-items: center;
  padding: 0px !important;
  margin: 0px !important;
}

.price-column {
  width: 700px !important;
  padding: 0px !important;
  margin: 0px !important;
  padding-left: 150px !important;
}
.font-size-pricing {
  font-size: 26px !important;
}

.font-size-pricing1 {
  font-size: 23px !important;
}

.font-size-pricing2 {
  font-size: 20px !important;
  font-weight: bold;
}

.font-size-pricing3 {
  font-size: 12px !important;
}

.pricing-page {
    padding-top: 100px; /* Ensures the section starts below the header */
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 75px;
    position: relative;
    background-image: url('../PAMed.png');
    background-size: cover;
    background-position: center;
    margin-bottom: -14px !important;
  }
  
  .pricing-container {
    max-width: 1200px;
    margin: 0 auto;
    background-color: rgba(255,255,255,.9);
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .pricing-header {
    text-align: center;
    background-color: #012f3f;
    color: white;
    padding: 20px;
    margin-bottom: 20px;
  }

  .pricing-header h2 {
    font-size: 35px !important;
    margin-bottom: 10px;
  }
  
  .pricing-header p {
    font-size: 1rem;
  }

  .pricing-header-2 {
    text-align: center;
    background-color: #be8e14;
    color: white;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .pricing-header-2 h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .pricing-header-2 p {
    font-size: 1rem;
    font-weight: bold;
    font-size: 23px;
  }
  
  .pricing-services {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .pricing-column {
    flex: 1;
    min-width: 200px;
    padding: 0px !important;
    margin: 0px !important;
    text-wrap: nowrap !important;
    text-align: left;
  }
  
  .pricing-column h4 {
    color: #012f3f;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .pricing-column ul {
    list-style-type: none;
    padding: 0;
  }
  
  .pricing-column ul li {
    font-size: 0.9rem;
    line-height: 1.6;
    margin-bottom: 5px;
  }
  
  .commercial-pricing {
    display: flex;
    justify-content: space-between;
    background-color: #ffcc00;
    padding: 20px;
  }
  
  .unit-type {
    text-align: center;
    flex: 1;
    padding: 10px;
  }
  
  .unit-type h4 {
    font-size: 1.2rem;
    color: #012f3f;
  }
  
  .unit-type p {
    font-size: 0.9rem;
    color: #333;
  }
  