.contact-page {
    position: relative;
    background-image: url('../PAMed.png'); /* Ensure the path is correct */
    background-size:cover;
    background-position:center;
    min-height: 85vh; /* Full viewport height */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom:-14px !important;
  }

  .contact-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5) !important;
  }
  
  .contact-container {
    width: 100%; /* Use the full available width */
    max-width: 1200px; /* Set max-width for large displays */
    border: none !important; /* Remove the border */
    color: white !important; /* Dark text for visibility */
    text-align: center;
    background-color: rgba(20, 45, 63, 0.85) !important;
  }

  .contact-container .form-field{
    color: white !important;
  }
  
  .contact-container h2 {
    color: white;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: none !important;
    color: white !important;
  }

  .label {
    color: white !important;
  }
  
  .contact-form .form-field {
    width: 90%; /* Take almost full width of the container */
    margin-bottom: 1.5rem;
    color: white !important;
  }
  
  .contact-form .ui.input input,
  .contact-form .ui.dropdown,
  .contact-form .ui.textarea {
    width: 100%; /* Full width for each input */
    background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent for form fields */
    color: #333; /* Dark text for inputs */
    border: none; /* Light border */
    transition: border-color 0.3s;
    padding: 10px; /* Add padding for comfort */
    border-radius: 4px; /* Rounded corners */
  }
  
  .contact-form .ui.button.primary {
    background-color: #f39c12; /* Orange button background */
    color: #fff; /* White text color */
    border-radius: 25px;
    transition: background-color 0.3s, color 0.3s;
    padding: 10px 20px; /* Larger button for better UX */
    width: 40%; /* Adjust button width as needed */
    max-width: 200px; /* Prevent button from getting too wide */
  }
  
  .contact-form .ui.button.primary:hover {
    background-color: #e67e22; /* Darker orange on hover */
  }

  .form-field {
    width: 90% !important;
    height: 100% !important;
  }

  .form-field2 {
    width: 90% !important;
    height: 100px !important;
  }
  
  .message.negative,
  .message.positive {
    background-color: rgba(255, 255, 255, 0.9); /* Slight transparency */
    color: #333; /* Dark text for messages */
    border: none; /* Remove message border */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 90%; /* Ensure message boxes are full width */
    margin-bottom: 1rem; 
    text-align: center;
    margin: 0 auto;
    margin-bottom: 25px;
  }
  
  .message.negative {
    border-left: 4px solid #e74c3c; /* Red left border for error messages */
  }
  
  .message.positive {
    border-left: 4px solid #2ecc71; /* Green left border for success messages */
  }