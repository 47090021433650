.app {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
}

.tabMain {
  background-color: #222;
  color: white;
  text-align: center;
  padding: 15px 0;
}

.tabs-group {
  padding-left: 160px;
  background-color: #222;
}

.nopage-padding {
  padding-top: 50px !important;
  text-align: center;
}

.logo-container {
  position: absolute;
  top: 20px;
  left: 20px;
}

.logo {
  width: 125px; /* Adjust size as needed */
  height: auto;
}

.trying-to-move {
  text-align: center !important;
}

.pButton-nopage {
  align-items: center !important;
  background-image: linear-gradient(165deg, #015470, #001b24 50%,#015470) !important;
  border-color: white !important;
  border-radius: 8px !important;
  color: white !important;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
  font-weight: 200 !important;
  font-size: 25px !important;
  justify-content: center !important;
  line-height: 1em !important;
  width: 45% !important;
  margin-top: 25px !important;
  padding: 10px !important;
}

.hamburger-menu {
  cursor: pointer;
  width: 30px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.line {
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: transform 0.3s ease;
}

.hamburger-menu.open .line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger-menu.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open .line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.tab-list {
  position: absolute;
  top: 40px;
  right: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
}

.tab-list.open {
  display: block;
}

.tab {
  cursor: pointer;
  padding: 8px 12px;
  margin-bottom: 6px;
  transition: background-color 0.3s ease;
}

.tab:hover {
  background-color: #f0f0f0;
}