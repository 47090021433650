.br-container .br-body {
    width: 1180px !important;
    padding-top: 30px !important;
}

.br-container {
    padding-top: 67px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 990px) {
    .br-container {
        padding-top: 67px;
        padding-bottom: 20px;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

.br-title {
    text-align: center;
    color: #be8e14;
    font-weight: normal !important;
    font-size: 35px !important;
}

.br-title-2 {
    text-align: left;
    font-weight: normal !important;
    padding-bottom: 20px;
}

.list-item-bold {
    font-weight: 400 !important;
}

.font-size-brpage {
    font-size: 14.5px !important;
    text-align: left !important;
    font-weight: normal !important;
}

@media only screen and (max-width: 990px) {
    .font-size-brpage {
        font-size: 14.5px !important;
        text-align: left !important;
        font-weight: normal !important;
        padding-right: 18px !important;
    }
}