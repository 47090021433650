.area-column {
    width: 100%;
    min-width: 350px !important;
    padding: 0px;
    margin-bottom: -10px !important;
}

.area-column1 {
    min-width: 160px !important;
    margin-right: 10px !important;
    margin-left: -10px !important;
}

.area-column-bottom {
    width: 100%;
    min-width: 350px !important;
    padding: 0px;
    margin-bottom: -10px !important;
    padding-bottom: 60px !important;
}

.area-column-bottom1 {
    min-width: 160px !important;
    padding-bottom: 50px !important;
}

.area-grid {
    width: 100%;
}

@media only screen and (max-width: 990px) {
    .area-grid {
        display: none !important;
    }
}

.area-grid1 {
    display: none !important;
    margin-left: -30px !important;
}

@media only screen and (max-width: 990px) {
    .area-grid1 {
        display: block !important;
    }
}

.area-row {
    width: 100%;
}

.area-title {
    padding-top: 100px !important;
}

.area-title-2 {
    text-align: center;
    width: 300px;
    min-width: 100px !important;
}

.area-title-3 {
    text-align: left;
}

.column-1 {
    width: 25% !important;
}

.container-1 {
    align-content: center !important;
}

.grid-1 {
    align-content: center !important;
    text-align: center;
}

.serve-body {
    font-size: 105% !important;
    font-weight: normal !important;
    text-align: left;
}