.section-blue {
    background-color: #012f3f;
    padding: 50px 0;
  }
  
  .section-white {
    background-color: white;
    padding: 50px 0;
  }
  
  .mission-text {
    font-size: 1.2em;
    font-weight: 400;
  }
  
  .our-team-page {
    padding-top: 50px;
  }

  .team-description {
    color: black;
    font-size: 1.1em;
    line-height: 1.6;
  }
  
  .team-description2 {
    color: white;
    font-size: 1.1em;
    line-height: 1.6;
  }
  
  .team-header {
    color: black;
    font-size: 2.2em;
    font-weight: bold;
  }

  .team-header2 {
    color: white;
    font-size: 2.2em;
    font-weight: bold;
  }
  
  .team-image {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    width: 50%;
  }