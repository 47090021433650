.included-header {
  text-align: center;
  font-size: 35px !important;
  color: #012f3f;
  font-weight: bold;
  padding-bottom: 1px;
}

.lat-column {
    color: white;
    width: 280px !important;
}

.lat-columns {
    color: white;
    width: 184px !important;
}

.lat-row {
  margin-bottom: -20px !important;
}

.pricing-page {
    padding-top: 100px; /* Ensures the section starts below the header */
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 75px;
    position: relative;
    background-image: url('../PAMed.png');
    background-size: cover;
    background-position: center;
    margin-bottom: -14px !important;
  }

  .size-change-owners {
    font-size: 39px !important;
    font-weight: normal !important;
  }

  @media only screen and (max-width: 990px) {
    .size-change-owners1 {
      font-size: 31px !important;
      font-weight: normal !important;
    }
  }
  
  .pricing-container {
    max-width: 1200px;
    margin: 0 auto;
    background-color: rgba(255,255,255,.9);
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  @media only screen and (max-width: 990px) {
    .pricing-container {
      display: none !important;
    }
}

.pricing-container2 {
  display: none !important;
}

@media only screen and (max-width: 990px) {
  .pricing-container2 {
    display: block !important;
    max-width: 1200px;
    margin: 0 auto;
    background-color: rgba(255,255,255,.9);
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}
  
  .pricing-header {
    text-align: center;
    background-color: #012f3f;
    color: white;
    padding: 20px;
    margin-bottom: 20px;
  }

  .commercial-pricing {
    text-align: center;
    background-color: #be8e14;
    padding: 20px;
    padding-bottom: 30px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
  }
  
  .pricing-header h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .pricing-header p {
    font-size: 1rem;
  }
  
  .pricing-services {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding: 15px;
  }
  
  .pricing-column {
    flex: 1;
    min-width: 200px;
    padding: 10px;
  }
  
  .pricing-column h4 {
    color: #012f3f;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .pricing-column ul {
    list-style-type: none;
    padding: 0;
  }
  
  .pricing-column ul li {
    font-size: 0.9rem;
    line-height: 1.6;
    margin-bottom: 5px;
  }
  
  .unit-type {
    text-align: center;
    flex: 1;
    padding: 0px;
    padding-bottom: 0 !important;
  }
  
  .unit-type h2 {
    font-size: 1.8rem;
    color: white;
    margin-bottom: -10px !important;
  }
  
  .unit-type p {
    font-size: 1.2rem;
    color: white;
  }
  