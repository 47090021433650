.about {
  display: none;
}

.aIcon {
  height: 20px;
}

.background-image {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
}



.blogo {
  height: 90px;
  width: 90px;
}

.body, h1, h2, p, a {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    text-decoration: none;
    color: inherit;
}

.contact {
  padding: 60px 20px;
  text-align: center;
  background-color: #012f3f;
  color: white;
  flex-grow: 1;
}

.contact h3 {
  font-size: 32px;
  margin-bottom: 20px;
}

.contact a {
  color: #f39c12;
  font-weight: bold;
  transition: color 0.3s;
}

.contact a:hover {
  color: #e67e22;
}

.contact-button {
  background-color: #be8e14 !important;
}

.c-width {
  width: 50% !important;
  padding-top: 25px;
  padding-bottom: 25px;
}

.del-white {
  margin: 0 !important;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s;
}

.font-1 {
  font-size: 40px;
  color: white !important;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.font-2 {
  font-size: 30px;
  color:silver;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 200;
  margin-top: 10px;
}

.header {
  display: none;
}
  
.hero {
  position: relative;
  width: 100%;
  top: 14px;
  height: 750px; /* Set the height of the hero section */
  overflow: hidden; /* Prevent overflow */
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .25);
}
  
.hero-content h2 {
    font-size: 42px;
    margin-bottom: 15px;
    color: #f1c40f;
}
  
.hero-content p {
    font-size: 20px;
    margin-bottom: 25px;
}
  
.hero-content .ui.button.primary {
    background-color: #f39c12;
    border-radius: 25px;
}
  
.hero-content .ui.button.primary:hover {
    background-color: #e67e22;
}

.home-container {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.home-container:hover .left-arrow{
  display: block !important;
}

.home-container:hover .right-arrow{
  display: block !important;
}

.left-arrow, .right-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: white;
  z-index: 10;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3; /* Ensure the arrows stay above everything */
  cursor: pointer;
  left: 0px;
  font-size: 35px !important;
  display: none !important;
}

@media only screen and (max-width: 990px) {
  .left-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3; /* Ensure the arrows stay above everything */
    cursor: pointer;
    left: -5px;
    size-adjust: 80% !important;
    font-size: 35px !important;
    display: block !important;
  }
}

.left-arrow:hover {
  color: #bfa614 !important;
}

.right-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3; /* Ensure the arrows stay above everything */
  cursor: pointer;
  right: 32px;
  font-size: 35px !important;
  display: none !important;
}

@media only screen and (max-width: 990px) {
  .right-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3; /* Ensure the arrows stay above everything */
    cursor: pointer;
    right: -5px;
    font-size: 35px !important;
    display: block !important;
  }
}

.right-arrow:hover {
  color: #bfa614 !important;
}

.logos {
    white-space: normal;
    word-wrap: break-word;
    text-align: center;
    width: 165px !important;
    overflow: hidden;
}

.logos:hover {
  color: #be8e14 !important;
}
  
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pButton {
  align-items: center !important;
  background-image: linear-gradient(165deg, #015470, #001b24 50%,#015470) !important;
  border-color: white !important;
  border-radius: 8px !important;
  color: white !important;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
  font-weight: 200 !important;
  font-size: 25px !important;
  justify-content: center !important;
  line-height: 1em !important;
  width: 45% !important;
  margin-top: 25px !important;
  padding: 10px !important;
}

.pButton:active,
.pButton:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .pButton {
    font-size: 24px;
    min-width: 196px;
  }
}

.pButton:hover {
  color: #be8e14 !important;
}

.properties {
    display: none;
}

.ten-app {
    color: #bfa614 !important;
}

.ui.container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Ensure the images fill the hero section */
  object-fit: cover; /* Cover while maintaining aspect ratio */
  transition: opacity 0.5s ease-in-out; /* Smooth transition for opacity */
  opacity: 0; /* Start hidden */
}

.slide.active {
  opacity: 1; /* Show active slide */
}